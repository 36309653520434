<template>
  <v-container>
    <v-row class="d-flex align-center container">
      <v-btn
        @click="searchModal = !searchModal"
        color="primary"
        class="my-4 mr-4"
      >
        <v-icon class="pr-2"> mdi-magnify</v-icon>
        Axtar
      </v-btn>
      <v-btn @click="resetSearch" v-if="searchModal" class="my-4">
        Təmizlə
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="dialog = !dialog" color="orange" dark class="mr-5">
        Yeni müştəri
      </v-btn>
      <v-btn
        download
        @click="exportCustomersInformation"
        color="orange"
        dark
        class="mr-0"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-row>

    <!-- Search -->
    <v-card class="my-4" outlined v-if="searchModal">
      <v-row class="pa-4">
        <v-col cols="4">
          <v-text-field
            v-model="searchForm.fullName"
            label="Ad Soyad Ata adı"
            @keyup="getAll"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchForm.mobileNumber"
            label="Mobil nömrə"
            type="number"
            @keyup="getAll"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchForm.contractNumber"
            label="Müqavilə nömrəsi"
            type="text"
            @keyup="getAll"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchForm.building"
            label="Bina"
            type="text"
            @keyup="getAll"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="searchForm.apartmentNumber"
            label="Mənzil No"
            type="number"
            @keyup="getAll"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-menu
            v-model="searchDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchForm.firstPaymentDate"
                label="İlkin ödəniş tarixi"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchForm.firstPaymentDate"
              @input="
                searchDateMenu = false;
                getAll();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="customers"
      :page="page"
      :server-items-length="totalCount"
      @update:items-per-page="handleLimitChange"
      @update:page="handlePageChange"
      sort-by="fullName"
      class="elevation-1"
      :footer-props="{
        'items-per-page-text': 'Sətir sayı',
        'items-per-page-all-text': 'Hamısı',
        pageText: '{0}-{1} / {2}',
      }"
    >
      <template v-slot:item.firstPaymentDate="{ item }">
        <!-- <span>{{ new Date(item.firstPaymentDate).toLocaleString().split(',')[0] }}</span> -->
        <span>{{ dateFormat(item.firstPaymentDate) }}</span>
      </template>
      <template v-slot:top>
        <!-- Create modal -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.fullName"
                      label="Ad Soyad Ata adı"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.fullName.$error &&
                        !$v.editedItem.fullName.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.mobileNumber"
                      label="Mobil nömrə"
                      type="number"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.mobileNumber.$error &&
                        !$v.editedItem.mobileNumber.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                    <p class="caption grey--text text-darken-1 mb-0">
                      Məsələn: 99450XXXXXXX
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.contractNumber"
                      label="Müqavilə nömrəsi"
                      type="text"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.contractNumber.$error &&
                        !$v.editedItem.contractNumber.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.building"
                      label="Bina"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.building.$error &&
                        !$v.editedItem.building.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.apartmentNumber"
                      label="Mənzil No"
                      type="number"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.apartmentNumber.$error &&
                        !$v.editedItem.apartmentNumber.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.firstPaymentDate"
                          label="İlkin ödəniş tarixi"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          :error-messages="
                            $v.editedItem.firstPaymentDate.$error &&
                            !$v.editedItem.firstPaymentDate.required
                              ? ['Zəhmət olmasa bölməni doldurun']
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.firstPaymentDate"
                        @input="dateMenu = false"
                        :show-current="false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nextPaymentDay"
                      label="Növbəti ödəmə günü"
                      type="number"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.nextPaymentDay.$error &&
                        !$v.editedItem.nextPaymentDay.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.paymentPeriod"
                      label="Ödəmə periodu"
                      type="number"
                      hide-details="auto"
                      :error-messages="
                        $v.editedItem.paymentPeriod.$error &&
                        !$v.editedItem.paymentPeriod.required
                          ? ['Zəhmət olmasa bölməni doldurun']
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close"> Ləğv et</v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Yadda saxla
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Delete modal -->
        <v-dialog v-model="dialogDelete" max-width="400px">
          <v-card>
            <v-card-title class="text-h5"
              >Silmək istədiyinizə əminsiniz?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete"
                >Imtina et
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Sil
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Status modal -->
        <v-dialog v-model="dialogStatus" max-width="400px">
          <v-card>
            <v-card-title class="text-h5">Statusu dəyiş</v-card-title>
            <v-card-text>
              <v-menu
                v-model="statusDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="statusDate"
                    label="Ödəniş tarixi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    :error-messages="
                      $v.statusDate.$error && !$v.statusDate.required
                        ? ['Zəhmət olmasa bölməni doldurun']
                        : []
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="disabledStatusDate"
                  v-model="statusDate"
                  @input="statusDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeStatusModal"
                >Imtina et
              </v-btn>
              <v-btn color="blue darken-1" text @click="changeStatus"
                >Dəyiş
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Payment modal -->
        <v-dialog v-model="dialogPayment" max-width="400px">
          <v-card>
            <v-card-title class="text-h5"
              >Müştərinin ödəmə tarixçəsi
            </v-card-title>
            <v-card-text>
              <v-list>
                <template v-if="paymentList && paymentList.length">
                  <v-list-item
                    :key="payment.id"
                    v-for="(payment, index) in paymentList"
                  >
                    <v-list-item-content>
                      <v-row class="align-center">
                        <v-col>
                          <v-list-item-title
                            >{{ index + 1 }}.
                            {{ dateFormat(payment.paymentDate) }}
                          </v-list-item-title>
                        </v-col>
                        <v-col>
                          <v-btn
                            class="mr-1"
                            small
                            @click="openPaymentDeleteModal(payment)"
                            icon
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn
                            small
                            icon
                            @click="openUpdateStatusModal(payment)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-dialog
                            v-model="dialogDeletePayment"
                            max-width="400px"
                          >
                            <v-card>
                              <v-card-title class="text-h5"
                                >Silmək istədiyinizə əminsiniz?
                              </v-card-title>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="red darken-1"
                                  text
                                  @click="closePaymentDelete"
                                  >Imtina et
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deletePayment"
                                  >Sil
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog
                            v-model="dialogUpdateStatus"
                            max-width="400px"
                          >
                            <v-card>
                              <v-card-title class="text-h5"
                                >Statusu dəyiş</v-card-title
                              >
                              <v-card-text>
                                <v-menu
                                  v-model="statusUpdateDateMenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="statusUpdateDate"
                                      label="Ödəniş tarixi"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      hide-details="auto"
                                      :error-messages="
                                        $v.statusUpdateDate.$error &&
                                        !$v.statusUpdateDate.required
                                          ? ['Zəhmət olmasa bölməni doldurun']
                                          : []
                                      "
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    :min="disabledUpdateStatusDate"
                                    v-model="statusUpdateDate"
                                    @input="statusUpdateDateMenu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="red darken-1"
                                  text
                                  @click="closeStatusUpdateModal"
                                  >Imtina et
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="updateStatus"
                                  >Dəyiş
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else> Məlumat tapılmadı</template>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialogPayment = false">Bağla</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openPaymentModal(item)">
          mdi-calendar
        </v-icon>
        <v-icon small class="mr-2" @click="openStatusModal(item)">
          mdi-update
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
      </template>
      <template v-slot:no-data> Məlumat yoxdur</template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    url: "https://sms.kresidence.az:8443",
    dialog: false,
    dialogDeletePayment: false,
    dialogUpdateStatus: false,
    dialogDelete: false,
    dialogStatus: false,
    dialogPayment: false,
    dateMenu: false,
    searchDateMenu: false,
    statusDateMenu: false,
    statusUpdateDateMenu: false,
    page: 1,
    perPage: 10,
    totalCount: 0,
    searchModal: false,
    searchForm: {
      fullName: null,
      mobileNumber: null,
      contractNumber: null,
      building: null,
      apartmentNumber: null,
      firstPaymentDate: null,
    },
    headers: [
      { text: "Ad Soyad Ata ad", value: "fullName", sortable: false },
      { text: "Mobil nömrə", value: "mobileNumber", sortable: false },
      { text: "Müqavilə nömrəsi", value: "contractNumber", sortable: false },
      { text: "Bina", value: "building", sortable: false },
      { text: "Mənzil No", value: "apartmentNumber", sortable: false },
      {
        text: "İlkin ödəniş tarixi",
        value: "firstPaymentDate",
        sortable: false,
      },
      { text: "Növbəti ödəmə günü", value: "nextPaymentDay", sortable: false },
      { text: "Ödəmə periodu", value: "paymentPeriod", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    customers: [],
    editedIndex: -1,
    editedItem: {
      fullName: null,
      mobileNumber: null,
      contractNumber: null,
      building: null,
      apartmentNumber: null,
      firstPaymentDate: null,
      nextPaymentDay: null,
      paymentPeriod: null,
    },
    defaultItem: {
      fullName: null,
      mobileNumber: null,
      contractNumber: null,
      building: null,
      apartmentNumber: null,
      firstPaymentDate: null,
      nextPaymentDay: null,
      paymentPeriod: null,
    },
    status: null,
    statusDate: null,
    disabledStatusDate: null,
    disabledUpdateStatusDate: null,
    paymentList: null,
    statusUpdateDate: null,
    customerId: null,
    paymentId: null,
  }),
  validations: {
    statusUpdateDate: {
      required,
    },
    statusDate: {
      required,
    },
    editedItem: {
      fullName: {
        required,
      },
      mobileNumber: {
        required,
      },
      contractNumber: {
        required,
      },
      building: {
        required,
      },
      apartmentNumber: {
        required,
      },
      firstPaymentDate: {
        required,
      },
      nextPaymentDay: {
        required,
      },
      paymentPeriod: {
        required,
      },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Yeni müştəri əlavə et"
        : "Müştəri məlumatlarını dəyiş";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getAll();
  },

  methods: {
    exportCustomersInformation() {
      const {
        fullName,
        mobileNumber,
        contractNumber,
        building,
        apartmentNumber,
        firstPaymentDate,
      } = this.searchForm;
      axios
        .get(`${this.url}/api/customers/export-excell`, {
          params: {
            FullName: fullName,
            MobileNumber: mobileNumber,
            ContractNumber: contractNumber,
            Building: building,
            ApartmentNumber: apartmentNumber,
            FirstPaymentDate: firstPaymentDate,
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          console.log("res ", res);

          let fileURL = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;

          let date =
            new Date().getDate() +
            "." +
            (new Date().getMonth() + 1) +
            "." +
            new Date().getFullYear() +
            " " +
            new Date().getHours() +
            "." +
            new Date().getMinutes();
          let fileName = "Customers " + date + ".xlsx";

          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);

          fileLink.click();

          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        });
    },
    deletePayment() {
      axios
        .delete(`${this.url}/api/customers/delete-payment?id=${this.paymentId}`)
        .then(() => {
          axios
            .get(`${this.url}/api/customers/get-by-id?id=${this.customerId}`)
            .then((res) => {
              this.paymentList = res.data.payments;
            });
          this.closePaymentDelete();
        });
    },

    updateStatus() {
      this.$v.statusUpdateDate.$touch();
      if (!this.$v.statusUpdateDate.$invalid) {
        axios
          .put(
            `${this.url}/api/customers/update-payment?id=${this.paymentId}&paymentDate=${this.statusUpdateDate}`
          )
          .then(() => {
            axios
              .get(`${this.url}/api/customers/get-by-id?id=${this.customerId}`)
              .then((res) => {
                this.paymentList = res.data.payments;
                this.closeStatusUpdateModal();
              });
          });
      }
    },

    closeStatusUpdateModal() {
      this.$v.statusUpdateDate.$reset();
      this.statusUpdateDate = null;
      this.status = null;
      this.disabledUpdateStatusDate = null;
      this.dialogUpdateStatus = false;
      this.paymentId = null;
    },

    handlePageChange(page) {
      this.page = page;
      this.getAll();
    },
    handleLimitChange(limit) {
      this.perPage = limit > 0 ? limit : this.totalCount;
      this.getAll();
    },

    resetSearch() {
      this.searchForm = {
        fullName: null,
        mobileNumber: null,
        contractNumber: null,
        building: null,
        apartmentNumber: null,
        firstPaymentDate: null,
      };
      this.getAll();
    },

    openUpdateStatusModal(item) {
      this.paymentId = item.id;
      this.dialogUpdateStatus = true;
      axios
        .get(
          `${this.url}/api/customers/get-payment-by-id?paymentId=${this.paymentId}`
        )
        .then((res) => {
          this.statusUpdateDate = res.data.paymentDate.split("T")[0];
        });
    },

    openPaymentDeleteModal(item) {
      this.paymentId = item.id;
      this.dialogDeletePayment = true;
    },

    closePaymentDelete() {
      this.paymentId = null;
      this.dialogDeletePayment = false;
    },

    openStatusModal(item) {
      this.dialogStatus = true;
      axios
        .get(`${this.url}/api/customers/get-by-id?id=${item.id}`)
        .then((res) => {
          this.status = res.data;
          if (this.status.payments.length) {
            this.disabledStatusDate =
              this.status.payments[this.status.payments.length - 1].paymentDate;
          }
        });
    },

    openPaymentModal(item) {
      this.dialogPayment = true;
      this.customerId = item.id;
      axios
        .get(`${this.url}/api/customers/get-by-id?id=${item.id}`)
        .then((res) => {
          this.paymentList = res.data.payments;
        });
    },

    closeStatusModal() {
      this.$v.statusDate.$reset();
      this.statusDate = null;
      this.status = null;
      this.disabledStatusDate = null;
      this.dialogStatus = false;
    },
    changeStatus() {
      this.$v.statusDate.$touch();
      if (!this.$v.statusDate.$invalid) {
        axios
          .post(
            `${this.url}/api/customers/create-payment?customerId=${this.status.id}&paymentDate=${this.statusDate}`
          )
          .then((res) => {
            this.getAll();
          });
        this.closeStatusModal();
      }
    },

    getAll() {
      const {
        fullName,
        mobileNumber,
        contractNumber,
        building,
        apartmentNumber,
        firstPaymentDate,
      } = this.searchForm;
      axios
        .get(`${this.url}/api/customers/get-all`, {
          params: {
            Offset: (this.page - 1) * this.perPage,
            Limit: this.perPage,
            FullName: fullName,
            MobileNumber: mobileNumber,
            ContractNumber: contractNumber,
            Building: building,
            ApartmentNumber: apartmentNumber,
            FirstPaymentDate: firstPaymentDate,
          },
        })
        .then((res) => {
          this.customers = res.data.items;
          this.totalCount = res.data.itemsCount;
        });
    },

    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.dialog = true;

      axios
        .get(`${this.url}/api/customers/get-by-id?id=${item.id}`)
        .then((res) => {
          this.editedItem = res.data;
          this.editedItem.firstPaymentDate =
            res.data.firstPaymentDate.split("T")[0];
        });
    },

    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`${this.url}/api/customers/delete?id=${this.editedItem.id}`)
        .then((res) => {
          this.getAll();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$v.$reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$v.editedItem.$touch();
        if (!this.$v.editedItem.$invalid) {
          axios
            .put(`${this.url}/api/customers/update`, this.editedItem)
            .then((res) => {
              this.getAll();
            });
          this.close();
        }
      } else {
        this.$v.editedItem.$touch();
        if (!this.$v.editedItem.$invalid) {
          axios
            .post(`${this.url}/api/customers/create`, this.editedItem)
            .then((res) => {
              this.getAll();
            });
          this.close();
        }
      }
    },
    dateFormat(date) {
      const d = new Date(date);
      return (
        new Date(d).getDate() +
        "." +
        (new Date(d).getMonth() + 1) +
        "." +
        new Date(d).getFullYear()
      );
    },
  },
};
</script>
